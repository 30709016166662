import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, notification, Tooltip } from "antd";
import React, { useContext, useState } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import OtpInput from "react-otp-input-rc-17";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import API_URL from "../../Constants/api-path";
import { ApiContext } from "../../pages/NotificationApi";
import {
  authActions,
  email_login,
  otp_login,
} from "../../features/auth.action";

function LoginForm() {
  const [form] = Form.useForm();
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [userData, setValue] = useState({
    password: "",
    email: "",
    loginType: "", //password or otp
  });
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useContext(ApiContext);

  const emailRules = [
    {
      type: "email",
      message: "Please enter a valid email address!",
    },
    {
      required: true,
      message: "Email is required!",
    },
  ];

  const handleInputs = (e) => {
    const key = e.target.name;
    let value = e.target.value;

    // if (key === "email") {
    //   value = value?.trim()?.toLowerCase();
    //   form.setFieldValue(key, value)
    //   form.validateFields()
    // }

    setValue({ ...userData, [key]: value });
  };

  // const loginUser = async (e) => {
  //   e.preventDefault();
  //   setIsAuth(false);
  //   setSpinner(true);
  //   try {
  //     const res = await fetch(`${API_PATH.ADMINLOGIN}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(userData),
  //     });

  //     const result = await res.json();
  //     if (result) {
  //       setSpinner(false);
  //       if (result?.status === 200 || result?.status === true) {
  //         localStorage.setItem("userData", JSON.stringify(result));
  //         if (result.token) {
  //           setIsAuth(true);
  //           localStorage.setItem("isAuth", true);
  //           localStorage.setItem(
  //             "ChangePassword",
  //             JSON.stringify(userData.password)
  //           );
  //           localStorage.setItem('lastActiveTime', new Date().toString()); // Set the last active time when the user logs in
  //           localStorage.setItem('lastLoginTime', new Date().getTime().toString()); // Set the last login time
  //           getData(result);
  //           history("/dashboard");

  //           notification.success({
  //             message: "Login Successful",
  //             icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
  //             style: {
  //               backgroundColor: "#2ecc71",
  //               color: "#fff !important",
  //               border: "1px solid #52c41a",
  //             },
  //             duration: 5,
  //             placement: "topRight",
  //           });
  //           // setTimeout(() => {
  //           //   if (JSON.parse(localStorage.getItem("userData"))) {

  //           //   }
  //           // }, 200);

  //         }
  //       } else {
  //         setSpinner(false);
  //         setError("Invalid email or password");
  //       }
  //     } else {
  //       notification.error({
  //         message: "Error: Something went wrong server error",
  //         icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
  //         style: {
  //           backgroundColor: "#e74c3c",
  //           color: "#fff",
  //           border: "1px solid #c0392b",
  //         },
  //         duration: 5,
  //         placement: "topRight",
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     setError("An error occurred while logging in");
  //   }
  // };

  const loginUser = async () => {
    const { email, password } = userData;
    localStorage.setItem("auth", email);
    localStorage.setItem("ChangePassword", JSON.stringify(password));
    dispatch(email_login({ email, password }));
    //! getData() NOTIFICATION API COMMENTED FOR NOW
  };

  const something = (event) => {
    if (event.keyCode === 13) {
      // loginUser(event);
      const { email, password } = userData;
      localStorage.setItem("auth", email);
      localStorage.setItem("ChangePassword", JSON.stringify(password));
      dispatch(email_login({ email, password }));
      //! getData() NOTIFICATION API COMMENTED FOR NOW
    }
  };

  const sendOTP = async () => {
    try {
      setLoadingEmail(true);
      const response = await fetch(`${API_URL.SENDOTP}/${userData?.email}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let login_response = await response.json();

      if (login_response?.status === 200 || login_response?.status === "200") {
        setLoadingEmail(false);
        form.setFieldValue("password", "");
        setValue({
          ...userData,
          password: "",
          loginType: "otp",
        });

        setTimeout(() => {
          console.log("ds");
          notification.success({
            message: "OTP Sent Successfully!",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }, 100);
      } else {
        setLoadingEmail(false);
        toast.error(login_response.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    } catch (error) {
      setLoadingEmail(false);
      notification.error({
        message: error.message,
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  };
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={() => {
          // loginUser()
          if (userData?.loginType === "otp") {
            let { email, password } = userData;
            dispatch(otp_login({ email, password }));
          } else {
            loginUser();
          }
        }}
      >
        {userData?.loginType === "" && (
          <Form.Item name="email" label="Email Address" rules={emailRules}>
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              type="text"
              name="email"
              value={userData?.email}
              onChange={handleInputs}
              autoComplete="nope"
              placeholder="Enter Your Email Address"
              size="large"
            />
          </Form.Item>
        )}
        {userData?.loginType !== "" && (
          <div className="mb-4 d-flex align-items-center">
            <Tooltip title="Back">
              <MdOutlineKeyboardBackspace
                style={{
                  cursor: "pointer",
                  fontSize: 22,
                  marginRight: 4,
                }}
                onClick={() => {
                  form.setFieldValue("password", "");
                  setValue({
                    ...userData,
                    password: "",
                    registrationType: "client",
                    loginType: "", //password or otp
                  });
                }}
              />
            </Tooltip>
            <span className="fw-bold fs-5">Email - </span>{" "}
            <span className="fs-5"> {userData?.email}</span>
          </div>
        )}

        {userData?.loginType === "password" && (
          <>
            {" "}
            <Form.Item
              name="password"
              label="Password"
              className="mb-2"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                name="password"
                onChange={handleInputs}
                value={userData?.password}
                id="password-field"
                size="large"
                autoComplete="nope"
                onKeyDown={(e) => something(e)}
                placeholder="Password"
              />
            </Form.Item>
            <div className="form-group text-end">
              <NavLink
                style={{
                  width: "fit-content",
                  marginLeft: "auto",
                }}
                onClick={() => {
                  dispatch(authActions.resetState());
                }}
                to="/forgot-password"
                className="register-btn px-2 text-end"
              >
                Forgot Password?
              </NavLink>
            </div>
          </>
        )}

        {userData?.loginType === "otp" && (
          <>
            <Form.Item
              name="password"
              label="OTP"
              rules={[
                {
                  required: true,
                  message: "Please input your OTP!",
                },
              ]}
            >
              {/* <div
              id="otp"
              className="d-flex justify-content-center align-items-center flex-row"
            > */}
              <OtpInput
                value={userData?.password}
                onChange={(otp) => {
                  setValue({
                    ...userData,
                    password: otp,
                  });
                }}
                numInputs={6}
                inputStyle={{
                  width: "100%",
                  height: "2.5rem",
                  margin: "0 0.5rem",
                  fontSize: "20px",
                  borderRadius: 4,
                  border: "1px solid #5746ec ",
                }}
              />
              {/* </div> */}
            </Form.Item>
          </>
        )}

        {userData?.loginType === "" && (
          <div className="d-md-flex align-items-center justify-content-center w-100 gap-2">
            <Form.Item shouldUpdate className="flex-1 w-100 text-center mb-0">
              <Button
                htmlType="button"
                className="login-btn"
                disabled={
                  userData?.email === "" ||
                  (userData?.email &&
                    form.getFieldError("email")?.length !== 0) ||
                  loadingEmail
                }
                onClick={() => {
                  let errorLen = form.getFieldError("email")?.length;
                  if (errorLen === 0) {
                    form.setFieldValue("password", "");
                    setValue({
                      ...userData,
                      password: "",
                      loginType: "password",
                    });
                  }
                }}
              >
                Login With Password
              </Button>
            </Form.Item>
            <div class="d-md-none or text-center">Or</div>
            <Form.Item shouldUpdate className="flex-1 w-100 text-center mb-0">
              <Button
                htmlType="button"
                className="login-btn m-0"
                disabled={
                  userData?.email === "" ||
                  (userData?.email &&
                    form.getFieldError("email")?.length !== 0) ||
                  loading ||
                  loadingEmail
                }
                onClick={() => {
                  let errorLen = form.getFieldError("email")?.length;
                  if (errorLen === 0) {
                    sendOTP();
                  }
                }}
              >
                Login With OTP{" "}
                {loadingEmail && (
                  <LoadingOutlined className="p-0" size={"small"} />
                )}
              </Button>
            </Form.Item>
          </div>
        )}

        {userData?.loginType !== "" && (
          <div className="form-group">
            <Form.Item shouldUpdate className="text-center">
              {() => (
                <Button
                  // onClick={loginUser}
                  className="btn login-btn"
                  type="primary"
                  htmlType="submit"
                  disabled={
                    loading ||
                    !form.isFieldsTouched(true) ||
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length
                  }
                >
                  {loading ? (
                    <>
                      <Spinner
                        size="sm"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          marginRight: "5px",
                        }}
                      />
                      Login
                    </>
                  ) : (
                    "Login"
                  )}
                </Button>
              )}
            </Form.Item>
          </div>
        )}
        {userData?.loginType === "otp" && (
          <div className="form-group m-0">
            <p className="text-center m-0">
              <NavLink
                className="register-btn px-2"
                onClick={() => {
                  if (!loadingEmail) {
                    sendOTP();
                  }
                }}
              >
                Resend OTP
              </NavLink>
            </p>
          </div>
        )}
      </Form>
    </>
  );
}
export default LoginForm;
