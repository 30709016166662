import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpen: false,
    inputs: false,
    podIdEdit: "",
    isLoadingCreate: false,
    isLoadingUpdate: false,
    companyListPod: [],
    allUsers: [],
    podList: [],
    selectedPOD: {},
    addData: {
        pod_name: "",
        company_id: [],
        team_lead: [],
        csm: [],
        acsm: [],
        lr: []
    }
}

const podSlice = createSlice({
    name: "podSlice",
    initialState: initialState,
    reducers: {
        setIsOpen: (state, { payload }) => {
            state.isOpen = payload
        },
        setPodIdEdit: (state, { payload }) => {
            state.podIdEdit = payload
        },
        setSelectedPOD: (state, { payload }) => {
            state.selectedPOD = payload
        },
        setIsLoadinCreate: (state, { payload }) => {
            state.isLoadingCreate = payload
        },
        setIsLoadinUpdate: (state, { payload }) => {
            state.isLoadingUpdate = payload
        },
        setAllUsers: (state, { payload }) => {
            state.allUsers = payload
        },
        setPodList: (state, { payload }) => {
            state.podList = payload
        },
        setInputs: (state, { payload }) => {
            state.inputs = payload
        },
        setAddData: (state, { payload }) => {
            state.addData = payload
        },
        setCompanyListPod: (state, { payload }) => {
            state.companyListPod = payload
        },

    }
})

export const podSliceReducer = podSlice.reducer
export const { setIsOpen, setAddData, setCompanyListPod, setInputs, setAllUsers, setIsLoadinCreate, setIsLoadinUpdate, setPodIdEdit, setPodList, setSelectedPOD } = podSlice.actions