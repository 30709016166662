import env from "./env";

const API_URL = {
  //<----------------Admin section--------------------------------->

  //Admin login
  ADMINLOGIN: `${env.BASE_URL}/admin/signin`,
  ADMINFORGOTPASSWORD: `${env.BASE_URL}/admin/forgot-password`,
  ADMINRESETPASSWORD: `${env.BASE_URL}/admin/reset-password`,
  SENDOTP: `${env.BASE_URL}/admin/send-login-otp`,
  OTPLOGIN: `${env.BASE_URL}/admin/verify-login-otp`,
  SENDOTPEXPERT: `${env.BASE_URL}/expert/send-login-otp`,
  OTPLOGINEXPERT: `${env.BASE_URL}/expert/verify-login-otp`,

  //manager section
  MANAGER_LIST: `${env.BASE_URL}/admin/admin-list`,
  MANAGER_ADD: `${env.BASE_URL}/admin/signup`,
  MANAGER_DELETE: `${env.BASE_URL}/admin/delete`,
  MANAGER_UPDATE: `${env.BASE_URL}/admin/update`,
  POD_LIST: `${env.BASE_URL}/admin/list-pod`,
  POD_CREATE: `${env.BASE_URL}/admin/create-pod`,
  POD_UPDATE: `${env.BASE_URL}/admin/update-pod`,
  ROLE_LIST: `${env.BASE_URL}/admin-role/all-admin-role`,

  //roles section
  POD_MEMBERS: `${env.BASE_URL}/admin/get-pod-user`,
  ROLE_Add: `${env.BASE_URL}/admin-role/create-admin-role`,
  ROLE_DELETE: `${env.BASE_URL}/admin-role/delete-admin-role`,
  ROLE_UPDATE: `${env.BASE_URL}/admin-role/update-admin-role`,
  ROLE_LIST_BYID: `${env.BASE_URL}/admin-role/admin-role-by-id`,

  //dashboard
  MONTH_WEEK_LIST: `${env.BASE_URL}/admin/dashboard-data`,
  PROJECT_STATS: `${env.BASE_URL}/project/dashboard-projects-filter/`,

  //expert section
  EXPERT_LIST: `${env.BASE_URL}/expert/all-expert`,
  ZOOM_EXPERT_LIST: `${env.BASE_URL}/expert/get-all-expert-name-email`,
  EXPERT_PROFILE: `${env.BASE_URL}/expert/expert-by-id`,
  GET_ALL_EXPERT_COMMENTS: `${env.BASE_URL}/expert/get-all-comments`,
  ADD_EXPERT_COMMENT: `${env.BASE_URL}/expert/add-comment`,
  UPDATE_EXPERT_COMMENT: `${env.BASE_URL}/expert/update-comment`,
  Delete_EXPERT_COMMENT: `${env.BASE_URL}/expert/delete-comment`,
  EXPERT_OPTION: `${env.BASE_URL}/expert/expert-filter-list`,
  VERIFY_CAPTCHA: `${env.BASE_URL}/expert/verify-captcha`,
  UPDATE_EMAIL: `${env.BASE_URL}/expert/update-email`,

  //client section
  CLIENT_LIST: `${env.BASE_URL}/client/all-companies`,
  CLIENT_LIST_ALL_NAME: `${env.BASE_URL}/admin/get-all-client-name`,
  CLIENT_BY_ID: `${env.BASE_URL}/client/company-by-id`,
  CLIENT_VIEW: `${env.BASE_URL}/client/client-by-companie`,
  CLIENT_UPDATE_PRICE: `${env.BASE_URL}/client/update-companie-price`,
  CLIENT_VERIFY: `${env.BASE_URL}/client/client-verify`,
  CLIENT_DELETE: `${env.BASE_URL}/client/delete`,
  ALL_CLIENT_LIST: `${env.BASE_URL}/client/client-dropdown-data`,
  CLIENT_ROLE: `${env.BASE_URL}/admin-role/all-client-role?page=1&limit=10`,
  MOVE_CLIENT_TO_MYNT: `${env.BASE_URL}/client/move-company`,
  ALL_CLIENTS_LIST: `${env.BASE_URL}/client/all-clients`,
  CHECK_CLIENT_EXIST: `${env.BASE_URL}/zoom-meeting/check-client-exist`,
  UPDATE_CLIENT_COMPANY: `${env.BASE_URL}/client/update-client-company`,

  //callrecords
  CALL_LIST: `${env.BASE_URL}/call-history/all-call-history`,
  CALL_CREATE: `${env.BASE_URL}/call-history/create`,//NOT IN USE
  CALL_UPDATE: `${env.BASE_URL}/call-history/update`,
  CALL_DELETE: `${env.BASE_URL}/call-history/delete`,
  CALL_OPTION: `${env.BASE_URL}/call-history/call-filter-list`,
  CALL_UPLOAD_CSV: `${env.BASE_URL}/call-history/upload-csv`,
  DOWNLOAD_CALL_HISTORY: `${env.BASE_URL}/call-history/download-call-histry`,
  DOWNLOAD_MEETING_CSV: `${env.BASE_URL}/zoom-meeting/download-meeting-csv`,

  //project
  PROJECT_LIVE: `${env.BASE_URL}/project/live-project`,
  PROJECT_COMPLETE: `${env.BASE_URL}/project/completed-project`,
  PROJECT_ON_HOLD: `${env.BASE_URL}/project/onhold-project`,
  PRJECT_DETAILS: `${env.BASE_URL}/project/project-by-id`,
  Client_Project_Expert_List: `${env.BASE_URL}/project/project-expert-list`,
  Add_Expert: `${env.BASE_URL}/project/add-expert`,
  PROJECT_STATUS: `${env.BASE_URL}/project/project-expert-status`,
  PROJECT_EXPERT_DELETE: `${env.BASE_URL}/project/delete-expert`,
  PROJECT_EXPERT_UPDATE: `${env.BASE_URL}/project/update-expert`,
  PROJECT_EXPERT_GET: `${env.BASE_URL}/project/project-expert-data`,
  PROJECT_INVITE_DELETE: `${env.BASE_URL}/project/remove-invite-team`,
  PROJECT_INVITE_ADD: `${env.BASE_URL}/project/invite-team`,
  PROJECT_DETAIL_STATUS: `${env.BASE_URL}/project/project-status`,
  GET_COMPLETED_CALL_DETAILS: `${env.BASE_URL}/project/get-project-call-details`,
  EXPORT_PROJECT: `${env.BASE_URL}/project/export-project`,


  //enquiries
  ENQUIRY_LIST: `${env.BASE_URL}/project/enquirie-list`,
  ENQUIRY_DELETE: `${env.BASE_URL}/project/enquirie-delete`,
  ENQUIRY_RECIEVED: `${env.BASE_URL}/project/enquirie-received`,

  //zoommeeting
  ZOOM_LIST: `${env.BASE_URL}/call-history/list-zoom-meetings`,
  ZOOM_ACCOUNT_LIST: `${env.BASE_URL}/call-history/list-zoom-account`,
  FETCH_MEETING_FROM_ZOOM: `${env.BASE_URL}/call-history/fetch-meeting-from-zoom`,//NOT IN USE
  ZOOM_COMPANY_LIST: `${env.BASE_URL}/call-history/list-client-companies`,//NOT IN USE
  ZOOM_ADD_MEETING: `${env.BASE_URL}/call-history/create-zoom-meetings`,
  ZOOM_DELETE_MEETING: `${env.BASE_URL}/call-history/delete-zoom-meetings`,
  GET_COMPANIES_LIST_FOR_MEETING: `${env.BASE_URL}/zoom-meeting/getall-company-details`,
  CLIENT_CREATE: `${env.BASE_URL}/client/create-client`,
  CLIENT_CREATE_MULTIPLE: `${env.BASE_URL}/client/create-client-multiple-user`,
  VALIDATE_CLIENT_EMAIL: `${env.BASE_URL}/client/check-client-domain`,
  UPDATE_CLIENT: `${env.BASE_URL}/client/update-client`,
  CSV_UPLOAD_MEETING: `${env.BASE_URL}/zoom-meeting/csvImport`,
  USER_PROJECT_LIST: `${env.BASE_URL}/zoom-meeting/get-live-project`,
  PROJECT_CLIENT_LIST: `${env.BASE_URL}/zoom-meeting/get-project-client`,
  PROJECT_EXPERT_LIST_ZOOM: `${env.BASE_URL}/zoom-meeting/get-project-expert`,
  UPDATE_POST_PAYMENT_DETAILS: `${env.BASE_URL}/call-history/post-payment-email`,
  //Zoominternalmeeting
  INTERNAL_MEETING: `${env.BASE_URL}/zoom-meeting`,
  GET_MEETING_BY_ID: `${env.BASE_URL}/zoom-meeting/get-zoom-recording`,
  UPDATE_TRANSCRIPT: `${env.BASE_URL}/zoom-meeting/update-transcript`,
  PUBLISH_MEETING: `${env.BASE_URL}/zoom-meeting/publish-meeting`,
  SAVE_DRAFT_MEETING: `${env.BASE_URL}/zoom-meeting/save-draft-meeting`,
  UPDATE_DRAFT_MEETING: `${env.BASE_URL}/zoom-meeting/update-draft-meeting`,
  LIST_DRAFT_MEETING: `${env.BASE_URL}/zoom-meeting/list-draft-meeting`,
  DRAFT_MEETING_BY_ID: `${env.BASE_URL}/zoom-meeting/draft-meeting-by-id`,
  DELETE_ZOOM_DRAFT_MEETING: `${env.BASE_URL}/zoom-meeting/delete-draft-meeting`,
  UPDATE_ZOOM_CALL_TIME: `${env.BASE_URL}/zoom-meeting/send-expert-post-call-email`,

  //CreditManagement
  ADD_CREDITS: `${env.BASE_URL}/credit-management`,
  GET_COMPANIES_LIST: `${env.BASE_URL}/credit-management/company-list`,
  GET_PAYMENT_HISTORY_BY_ID: `${env.BASE_URL}/credit-management/credit-payment-list`,
  GET_TRANSACTION_HISTORY_BY_ID: `${env.BASE_URL}/credit-management/credit-transaction-list`,
  GET_CREDIT_VALUE_IN_DOLLOR: `${env.BASE_URL}/credit-management/get-credit-value`,
  GET_CREDIT_USAGE_BY_COMPANY_ID: `${env.BASE_URL}/credit-management/get-credit-usage-value`,
  UPDATE_CREDIT_USAGE_BY_COMPANY_ID: `${env.BASE_URL}/credit-management/update-credit-usage-settings`,
  HANDLE_REFUND_CREDITS: `${env.BASE_URL}/credit-management/refund-credit`,
  HANDLE_CREDIT_UPDATE: `${env.BASE_URL}/credit-management/credit-adjustment`,

  //AddExpert
  ADD_EXPERT_LIST: `${env.BASE_URL}/expert/all-temp-expert`,
  ADD_EXPERT_TAG: `${env.BASE_URL}/expert/all-tags`,
  ADD_EXPERT_INDUSTRY: `${env.BASE_URL}/expert/all-industry`,
  ADD_EXPERT_CATEGORIES: `${env.BASE_URL}/expert/all-categories`,
  SEND_QC: `${env.BASE_URL}/expert/send-qc`,
  UPDATE_QC_UPDATE: `${env.BASE_URL}/expert/update-qc-expert`,
  UPDATE_PT_BUSTER: `${env.BASE_URL}/expert/update-pt-bustor`,
  UPDATE_TEMP_EXPERT: `${env.BASE_URL}/expert/updateTempExpertStatus`,
  EXPERT_FILTER_SEARCH: `${env.BASE_URL}/expert/expert-filter-search`,

  //QcExperts
  Qc_EXPERT_LIST: `${env.BASE_URL}/expert/all-temp-qc-expert`,
  Qc_LINKEDIN_ERROR_PROFILE_LIST: `${env.BASE_URL}/expert/get-linkedin-error-profile`,
  TEMP_EXPERT_DETAILS: `${env.BASE_URL}/expert/temp-expert-by-id`,
  TEMP_EXPERT_PROCESS_AGAIN: `${env.BASE_URL}/expert/process-again`,
  TEMP_EXPERT_SUBMIT: `${env.BASE_URL}/expert/submit-temp-expert`,


  //Consulting
  CONSULTING_LIST: `${env.BASE_URL}/expert/all-temp-consulting-expert`,
  CONSULTING_ADD: `${env.BASE_URL}/expert/add-temp-expert`,
  CONSULTING_DELETE: `${env.BASE_URL}/expert/delete-temp-expert`,
  CONSULTING_ADD_EXPERT: `${env.BASE_URL}/expert/consulting-to-tempexpert`,


  //Logs
  LOGS_LIST: `${env.BASE_URL}/expert/all-logs`,

  //Exotel
  EXOTEL_LIST: `${env.BASE_URL}/call-history/all-exotel-call`,
  EXOTEL_CONNECT: `${env.BASE_URL}/call-history/call-connect`,

  //Change password
  CHANGE_PASSWORD: `${env.BASE_URL}/admin/change-password`,

  //profile
  PROFILE_GET: `${env.BASE_URL}/admin/admin-by-id`,
  PROFILE_UPDATE: `${env.BASE_URL}/admin/update`,

  //chat
  CHAT_INSERT: `${env.BASE_URL}/admin/chat-insert`,
  CLIENT_ADMIN_CHAT_DATA: `${env.BASE_URL}/admin/chat-data-by-project`,
  CHAT_DELETE: `${env.BASE_URL}/admin/chat-delete`,
  CHAT_EDIIT: `${env.BASE_URL}/admin/chat-update`,
  CHAT_SEEN: `${env.BASE_URL}/admin/chat-seen`,
  CHAT_SEEN_USER_DATA: `${env.BASE_URL}/admin/chat-seen-user-data`, //NOT IN USE

  //<----------------Expert section--------------------------------->

  //Expert login
  EXPERT_LOGIN: `${env.BASE_URL}/expert/expert-signin`,


  //Expert registration
  EXPERT_REGISTRATION: `${env.BASE_URL}/expert/expert-signup`,
  EXPERT_REGISTRATION_LINKDIN: `${env.BASE_URL}/expert/linkedin-signup-expert`,
  EXPERT_LOGIN_VERIFY: `${env.BASE_URL}/expert/linkedin-signin-expert`,

  //Expert call records
  EXPERT_CALL_LIST: `${env.BASE_URL}/expert/expert-call-history`,
  //Expert forgot password
  EXPERT_FORGOT_PASSWORD: `${env.BASE_URL}/expert/forgot-password`,

  //Expert forgot password
  EXPERT_RESET_PASSWORD: `${env.BASE_URL}/expert/reset-password`,

  //Expert forgot password
  EXPERT_CHANGE_PASSWORD: `${env.BASE_URL}/expert/change-password`,

  //Expert registration


  //Expert dashboard
  EXPERT_PROFILE_LIST: `${env.BASE_URL}/expert/expert-data`,
  EXPERT_PROFILE_UPDATE: `${env.BASE_URL}/expert/expert-profile-update`,
  EXPERT_DELETE: `${env.BASE_URL}/expert/delete`,

  //Expert payment
  EXPERT_PAYMENT_ADD: `${env.BASE_URL}/expert/expert-payment-add`,
  EXPERT_PAYMENT_LIST: `${env.BASE_URL}/expert/expert-payment-list`,
  EXPERT_PAYMENT_UPDATE: `${env.BASE_URL}/expert/expert-payment-update`,
  EXPERT_PAYMENT_DELETE: `${env.BASE_URL}/expert/expert-payment-delete`,
  EXPERT_PAYMENT_PRIMATY_SET: `${env.BASE_URL}/expert/payment-set-primary`,

  //Compliance
  COMPLIANCE_ADD: `${env.BASE_URL}/expert/sign-upload`,
  COMPLIANCE_GET: `${env.BASE_URL}/expert/sign-data`,

  //Get Project Expert List
  PROJECT_EXPERT_LIST: `${env.BASE_URL}/project/project-expert-list`,
  CLIENT_LIST_PROJECT: `${env.BASE_URL}/project/list-client-projects`,
  ADD_PROJECT_IN_EXPERT: `${env.BASE_URL}/expert/add-project-in-expert`,

  //Team Project 
  TEAM_PROJECT_LIST: `${env.BASE_URL}/project/team-project-list`,
  TEAM_PROJECT_ADD: `${env.BASE_URL}/project/team-project`,
  TEAM_PROJECT_INVITE_REMOVE: `${env.BASE_URL}/project/remove-invite-team-project`,
  TEAM_PROJECT_UPDATE: `${env.BASE_URL}/project/remove-invite-team-project`,
  TEAM_PROJECT_INVITE: `${env.BASE_URL}/project/invite-team-project`,
  TEAM_EXPERT_ADD: `${env.BASE_URL}/project/team-expert-add`,
  TEAM_EXPERT_LIST: `${env.BASE_URL}/project/get-all-team-expert`,
  TEAM_EXPERT_BY_ID: `${env.BASE_URL}/project/get-team-expert`,//NOT IN USE
  TEAM_EXPERT_EDIT: `${env.BASE_URL}/project/team-expert-update`,
  TEAM_EXPERT_DELETE: `${env.BASE_URL}/project/delete-team-expert`,
  TEAM_COMPLETE_PROJECT: `${env.BASE_URL}/project/team-complete-project-list`,
  TEAM_CLIENT_NAME: `${env.BASE_URL}/project/get-client-team-project`,//NOT IN USE
  TEAM_HOLD_PROJECT_LIST: `${env.BASE_URL}/project/team-hold-project-list`,

  //notification
  NOTIFICATION_LIST: `${env.BASE_URL}/admin/all-notification`,
  UPDATE_NOTIFICATION_READ_STATUS: `${env.BASE_URL}/admin/seen-notification`,

  //lead
  LEAD_LIST: `${env.BASE_URL}/client/user-leads`,
  LEAD_LOGIN_CREDENTIAL: `${env.BASE_URL}/client/create-client`,
  LEAD_DEMO_STATUS: `${env.BASE_URL}/client/update-demouser-status`,

  UPLOAD_RESUME: `${env.BASE_URL}/expert/resume-upload`,
  UPLOAD_FILE: `${env.BASE_URL}/project/upload-project-file`,
  DELETE_FILE: `${env.BASE_URL}/project/delete-project-file`,

  UPLOAD_DOCUMENT: `${env.BASE_URL}/secondary-report`,

  //DNC
  GET_DNC_LIST: `${env.BASE_URL}/client/dnc-list`,
  ADD_DNC: `${env.BASE_URL}/client/add-dnc`,
  UPDATE_DNC: `${env.BASE_URL}/client/update-dnc`,
  DELETE_DNC: `${env.BASE_URL}/client/delete-dnc`,

  //scrap
  SCRAP_LINKEDIN_PROFILE: `${env.BASE_URL}/expert/expert-profile-fetch-by-scrapin`
};

export default API_URL;
