import React from "react";
import { Spin } from "antd";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
// import nextyn_logo from "../../src/assests/images/nextyn_small_logo.png";
import nextyn_logo from "../../src/assests/images/NX19.png";

const PageLoader = () => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                backgroundColor: "#f0f2f5",
            }}
        >
            <Spin
                indicator={
                    <AiOutlineLoading3Quarters
                        style={{
                            fontSize: 48,
                            color: "#5746EB",
                            animation: "spin 1s linear infinite",
                        }}
                    />
                }
            />
            <div
                style={{
                    fontSize: 24,
                    fontWeight: 600,
                    // color: "#5746EB",
                    // animation: "rotateLogo 2s linear infinite",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <img src={nextyn_logo} alt="nextyn_logo" width={"300px"}
                // style={{
                //     animation: "rotateLogo 3s ease-in-out infinite",
                //     transformStyle: "preserve-3d",
                // }}
                />
                {/* <span style={{
                    color: "black",
                    fontSize: 40,
                    fontWeight: 500,
                    marginInlineStart: 8
                }}>NEXTYN</span> */}
            </div>
            <style>
                {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
            @keyframes rotateLogo {
            0% { transform: rotateY(0deg); }
            50% { transform: rotateY(180deg); }
            100% { transform: rotateY(360deg); }
        }
        `}
            </style>
        </div>
    );
};

export default PageLoader;
